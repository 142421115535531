import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import './style.css';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { MdClose } from 'react-icons/md';

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    z-index: 1000;
`;

const ModalContent = styled.div`
    width: 100%;
    padding: 20px;
    border-radius: 8px;

    text-align: center;
`;

const ModalImage = styled.img<{ porcent: number }>`
    width: ${({ porcent }) => porcent + `%`};
    object-fit: fill;
    margin-bottom: 10px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 0;
    height: 30px;
    margin-left: 20px;
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: ${Colors.Yellow};
    font-weight: 600;
    color: ${Colors.Blue};
    font-size: medium;
    border-radius: 20px;
    cursor: pointer;
`;

const ZoomButton = styled.button<{ top?: number; left?: number }>`
    position: relative;
    left: ${({ left }) => (left ? left : 0)};
    top: ${({ top }) => (top ? top : 2)};
    height: 30px;
    width: 45px;
    margin-left: 20px;
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: ${Colors.Yellow};
    font-weight: 600;
    color: ${Colors.Blue};
    font-size: medium;
    border-radius: 20px;
    cursor: pointer;
`;

interface IAmpliarImg {
    show: boolean;
    onClose: () => void;
    imgSrc: string;
    setPorcent: (value: number) => void;
    porcent: number;
}

const AmpliarImg: React.FC<IAmpliarImg> = ({ show, onClose, imgSrc, setPorcent, porcent }: IAmpliarImg) => {
    if (!show) {
        return null;
    }

    return (
        <ModalBackdrop className="modal">
            <div style={{ position: 'relative', height: '100%' }}>
                <CloseButton onClick={onClose}>
                    <MdClose size={20} />
                </CloseButton>
                <div style={{ position: 'relative', top: '80%', width: '100px', height: '60px', right: 0 }}>
                    <ZoomButton onClick={() => setPorcent(porcent + 10)} top={2} left={0}>
                        <FiZoomIn size={22} />
                    </ZoomButton>
                    <ZoomButton onClick={() => setPorcent(porcent - 10)} top={10}>
                        <FiZoomOut size={22} />
                    </ZoomButton>
                </div>
            </div>

            <div
                style={{
                    overflow: 'auto',
                    overflowY: 'scroll',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    width: IsCustomMaxWidth(1300) ? '100%' : '80%',
                }}
            >
                <ModalContent onClick={e => e.stopPropagation()}>
                    <ModalImage porcent={porcent} src={imgSrc} alt="Expanded view" />
                </ModalContent>
            </div>
        </ModalBackdrop>
    );
};

export default AmpliarImg;
